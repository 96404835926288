<template>
  <div class="practice content">
    <Tabs></Tabs>
    <!-- 进度条 -->
    <div class="content_box">
      <div class="content">
        <div class="step_box">
          <div class="step_top">
            <img src="../../../../assets/img/dizhi.png" alt="" />
            <div class="breadcrumb_box">
              <p @click="back()">章节练习</p>
              &nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;
              <p>{{ name }}</p>
            </div>
          </div>
          <div class="step_down">
            <div class="step">
              <el-progress
                :percentage="step.percentage"
                :color="step.customColor"
                :stroke-width="8"
              ></el-progress>
            </div>
            <div class="step_txt">
              已完成
              <p>{{ is_num + 1 }}</p>
              道 / 共计
              <p>{{ all_array.length }}</p>
              道
            </div>
          </div>
        </div>
      </div>
      <!-- 无权限状态 -->
      <div
        v-if="code_status.code != 1"
        class="content"
        style="background: rgba(250, 251, 253, 1); margin-top: 20px"
      >
        <div class="no_access" style="margin: 0 auto">
          <div class="no_enroll" v-if="show_ipt == false">
            <img src="../../../../assets/img/wukemu.png" alt="" />
            <div>{{ code_status.msg }}</div>
            <button type="button" @click="show_ipt = true">立即报名</button>
          </div>
          <!-- 联系客服 -->
          <div class="contact_box" v-if="show_ipt == true">
            <p>联系客服</p>
            <p>
              请选择您打算报考的专业和电话，后续会有客服与您联系确定报班事宜
            </p>
            <div class="ipt_box">
              <div>
                <div>*</div>
                报考专业
              </div>
              <el-select
                v-model="formInline.subject_id"
                placeholder="请选择您的报考专业"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.subject_id"
                  :label="item.name"
                  :value="item.subject_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="ipt_box" style="margin-top: 30px">
              <div>
                <div>*</div>
                手机号
              </div>
              <el-input
                placeholder="请输入您的手机号"
                v-model="formInline.mobile"
                maxlength="11"
                style="margin-top: 12px"
              ></el-input>
            </div>
            <button type="button" @click="onSubmit">点击提交</button>
          </div>
        </div>
      </div>
      <OneTest v-else :data="all_array" @isNum="isNum" :type="2" />
    </div>
  </div>
</template>

<script>
import OneTest from "../../../../components/one_test/index.vue";
import api from "@/api/api";
export default {
  components: {
    OneTest,
  },
  name: "practice",
  data() {
    return {
      formInline: {
        mobile: "", // 用户手机号
        subject_id: "", // 专业id
      },
      code_status: {
        code: 1,
      }, //无权限
      subjectList: [], //科目列表报名
      show_ipt: false, //无权限报名
      dataCode: false,
      dialogVisible: false,
      step: {
        name: "",
        percentage: 1, //进度条
        customColor: "#409eff", // 进度条颜色值
        customColors: [
          { color: "#f56c6c", percentage: 20 },
          { color: "#e6a23c", percentage: 40 },
          { color: "#5cb87a", percentage: 60 },
          { color: "#1989fa", percentage: 80 },
          { color: "#6f7ad3", percentage: 100 },
        ], // 进度条颜色值
      },
      is_num: 0, //当前第几页
      total: 0, //总题数
      all_array: [],
    };
  },
  created() {
    this.name = this.$route.query.name;
    this.getTopicNumber();
    let page_size = Math.floor(Math.random() * 10 + 1);
    this.getQuestion(page_size);
    this.getSubject();
  },
  methods: {
    //获取科目列表报名
    // 科目列表
    getSubject() {
      api.subject().then((res) => {
        this.subjectList = res.data.data;
      });
    },
    //提交手机号
    // 提交手机号
    onSubmit() {
      let mobile = this.formInline.mobile; //用户手机号
      let ts = Date.parse(new Date()); //当前时间戳
      let cid = 2; //渠道号
      let subject_id = this.formInline.subject_id; //科目id
      let seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      let sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.formInline.subject_id) {
        this.$message.error({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.formInline.mobile == "" ||
        this.formInline.mobile <= 10 ||
        !reg.test(this.formInline.mobile)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$message.error({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$message.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.formInline = {};
            this.$cookies.set("mobile", mobile, 30);
            let routerName = this.$route.params.router_name;
            if (routerName == "历年真题") {
              this.$router.push({
                name: "TrueTopic",
                params: {
                  is_pageid: 9,
                  select_id: 3,
                },
              });
            } else if (routerName == "模拟试题") {
              this.$router.push({
                name: "Simulation",
                params: {
                  is_pageid: 9,
                  select_id: 4,
                },
              });
            } else if (routerName == "做题记录") {
              this.$router.push({
                name: "Record",
                params: {
                  is_pageid: 9,
                  select_id: 5,
                },
              });
            }
          } else {
            this.$message.error({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
          }
        });
      }
    },
    parentHandleclick() {
      this.is_2_show = true;
      this.isReveal = false;
    },
    //当前第几页
    isNum(val) {
      // console.log(val);
      this.is_num = val;
      this.step.percentage =((Number(val) + 1) / this.all_array.length) * 100;
    },
    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    //   获取问题列表
    getQuestion(page_size) {
      // // console.log(page_size);

      var data = {
        chapter_category: 29,
        ex_chapter_id: this.$route.params.chapter_id,
      };
      api.chapter_question_list(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.all_array = res.data.data.list;
          this.step.percentage =(1 / res.data.data.list.length) * 100;
          this.code_status = res.data;
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
          this.code_status = res.data;
        } else {
          this.code_status = res.data;
        }
      });
    },
    // 获取做题数上报接口
    getTopicNumber() {
      let a = 0;
      let b = 0;
      let data = [];
      this.all_array.forEach((x) => {
        switch (x.qtype) {
          case 1:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });

              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
          case 2:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 3:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 4:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });
              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
        }
      });
      let params = [];
      data.forEach((e) => {
        (e.total_count = a), (e.err_count = b);
        if (params.length <= 0) {
          return params.push(e);
        }
        for (let i = 0; i < params.length; i++) {
          if (
            params[i].two_chapcate_id != e.two_chapcate_id &&
            params[i].three_chapcate_id != e.three_chapcate_id
          ) {
            params.push(e);
          }
        }
      });
      if (params.length > 0) {
        let a = "";
        for (let index = 0; index < params.length; index++) {
          if (a == "") {
            a = JSON.stringify(params[index]);
          } else {
            a = a + "|" + JSON.stringify(params[index]);
          }
        }
        params = a;
        api.question_count(params).then((res) => {
          // console.log("做题数", res);
        });
      }
    },
  },
  beforeDestroy() {
    // this.getTopicNumber();
  },
};
</script>

<style lang="scss" scoped>
// background: #f5f5f5;
// 内容区
.content_box {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  // padding-bottom: 300px;
  .content {
    width: 1200px;
    margin: auto;
    overflow: hidden;

    // 进度条
    .step_box {
      width: 1120px;
      height: 80px;
      background: #fff;
      margin: 16px 0 10px;
      padding: 0 40px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      .step_top {
        display: flex;
        align-items: center;
        // height: 23px;
        // margin: 28px 0 10px;
        img {
          width: 16px;
          height: 18px;
          margin-right: 10px;
        }
        .breadcrumb_box {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          p:nth-child(1) {
            color: #666;
            font-weight: 400;
            cursor: pointer;
          }
          p:nth-child(2) {
            display: block;
            width: 416px;
            color: #222222;
            font-weight: 500;
          }
        }
      }
      .step_down {
        display: flex;
        align-items: center;
        .step {
          width: 260px;
          margin-right: 30px;
          /deep/.el-progress-bar__outer {
            width: 260px;
          }
          /deep/.el-progress__text {
            display: none;
          }
        }
        .step_txt {
          display: flex;
        }
      }
    }
  }
}

// 没有权限
.no_access {
  width: 820px;
  height: 484px;
  background: #fafbfd;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;
  // 立即报名页面
  .no_enroll {
    display: flex;
    flex-flow: column;
    align-items: center;
    img {
      width: 286px;
      height: 224px;
      margin-top: 69px;
    }
    div {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin: 29px 0 15px;
    }
    button {
      width: 200px;
      min-height: 46px;
      background: #3e7eff;
      border-radius: 6px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  // 联系客服页面
  .contact_box {
    width: 500px;
    display: flex;
    flex-flow: column;
    // align-items: center;
    p:nth-child(1) {
      font-size: 20px;
      font-family: DFPSongW9-GB;
      font-weight: 580;
      color: #333333;
      text-align: center;
      margin-top: 50px;
    }
    p:nth-child(2) {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin: 11px auto 55px;
    }
    .ipt_box {
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        div {
          color: #d44847;
        }
      }
      .el-select {
        margin-top: 12px;
        /deep/ .el-input__inner {
          width: 500px;
          height: 50px;
          background: #fafbfd;
        }
      }
      /deep/.el-input__inner {
        width: 500px;
        height: 50px;
        background: #fafbfd;
        // margin-top: 12px;
      }
    }
    button {
      width: 200px;
      height: 50px;
      background: #3e7eff;
      border-radius: 6px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: 40px auto 0;
    }
  }
}
</style>